<template>
  <div>
    <FiltriDocEnte
      @getDocumentiEnte="searchDocEnteList"
      @resetFilters="resetFilters"
    />
    <TableDocEnte
      @getDocumentiEnte="getDocumentiEnte"
      @resetFilters="resetFilters"
      :ente="ente"
    />
  </div>
</template>

<script>
import { defineComponent, onMounted, computed, getCurrentInstance } from "vue";
import { useStore } from "vuex";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import FiltriDocEnte from "@/components/components-fit/enti-aggregati/documenti/FiltriDocEnte.vue";
import TableDocEnte from "@/components/components-fit/enti-aggregati/documenti/TableDocEnte.vue";

export default defineComponent({
  name: "documenti-ente",
  components: {
    FiltriDocEnte,
    TableDocEnte,
  },
  props: {
    ente: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Documenti Ente", [
        "Lista Ente",
        "Dettaglio Ente",
      ]);
    });
    const store = useStore();

    const instance = getCurrentInstance();
    const globalApi =
      instance.appContext.config.globalProperties.$apiListGlobal;

    const stagione = computed(() => store.getters.stagioneSelected);
    const rowsToSkip = computed(() => store.getters.rowsToSkipDocEnte);
    const fetchRows = computed(() => store.getters.fetchRowsDocEnte);
    const sortColumn = computed(() => store.getters.sortColumnDocEnte);
    const sortOrder = computed(() => store.getters.sortOrderDocEnte);

    const data_da = computed(() => store.getters.dataDaDocEnte);
    const data_a = computed(() => store.getters.dataADocEnte);
    const id_tipo = computed(() => store.getters.idTipoDocEnte);
    const acl = computed(() => store.getters.permessiDocEnte);

    const getDocumentiEnte = () => {
      store.dispatch("setStoreListData", {
        keys: {
          id_societa: props.ente.id,
          anno: stagione.value,
          data_da: data_da.value,
          data_a: data_a.value,
          id_tipo: id_tipo.value,
          acl: acl.value,
          rowsToSkip: rowsToSkip.value,
          fetchRows: fetchRows.value,
          sortColumn: sortColumn.value,
          sortOrder: sortOrder.value,
        },
        apiLink: globalApi.AFFILIAZIONI_ENTI_AGGREGATI_DOCUMENTI_LIST,
        itemName: "documenti_ente_list",
      });
    };
    getDocumentiEnte();

    const resetFilters = () => {
      store.commit("resetFiltersDocEnte");
      getDocumentiEnte();
    };
    getDocumentiEnte();

    const loaded = computed(() =>
      store.getters.getStateFromName("loadeddocumenti_ente_list")
    );

    const searchDocEnteList = () => {
      if (!loaded.value) return;
      store.commit("setRowsToSkipDocEnte");
      getDocumentiEnte();
    };

    return {
      getDocumentiEnte,
      resetFilters,
      searchDocEnteList,
    };
  },
});
</script>
