<template>
  <div>
    <div class="card shadow">
      <div class="pt-4 pb-4 pe-4 ps-4" @keyup.enter="$emit('getDocumentiEnte')">
        <div class="row">
          <div class="col-sm-4 filter">
            <SelectInput
              :options="societa_tipo_documenti"
              name="ente_tipo_documenti"
              placeholder="Tipologia"
              :value="idTipoDocEnte"
              @changeSelect="setIdTipoDocEnte($event)"
            />
          </div>
          <div class="col-sm-4 filter">
            <Datepicker
              v-model="dataDaDocEnte"
              yearPicker
              placeholder="Data da"
              :format="format"
              :monthChangeOnScroll="false"
              :enableTimePicker="false"
              inputClassName="h-28"
              @update:model-value="setDataDaDocEnte"
              autoApply
            />
          </div>
          <div class="col-sm-4 filter">
            <Datepicker
              v-model="dataADocEnte"
              yearPicker
              placeholder="Data a"
              :format="format"
              :monthChangeOnScroll="false"
              :enableTimePicker="false"
              inputClassName="h-28"
              @update:model-value="setDataADocEnte"
              autoApply
            />
          </div>
        </div>
        <div class="text-center mt-5">
          <button
            type="button"
            data-bs-toggle=""
            data-bs-target=""
            aria-expanded="false"
            aria-controls=""
            class="badge rounded-pill bg-light text-gray-600 ms-4"
            @click="$emit('getDocumentiEnte')"
            :disabled="!loaded"
          >
            Cerca <i class="bi bi-search fs-6 text-gray-600"> </i>
          </button>
          <span
            type="button"
            data-bs-toggle=""
            data-bs-target=""
            aria-expanded="false"
            aria-controls=""
            class="badge rounded-pill bg-light text-gray-600 ms-4"
            @click="$emit('resetFilters')"
          >
            <i class="bi bi-arrow-clockwise fs-6 text-gray-600"> </i
          ></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "vue3-date-time-picker/dist/main.css";
import { defineComponent, computed, getCurrentInstance, ref, watch } from "vue";
import Datepicker from "vue3-date-time-picker";
import { format } from "@/composables/formatDate";
import { useStore } from "vuex";
import SelectInput from "@/components/components-fit/utility/inputs/SelectInput.vue";

export default defineComponent({
  name: "filtri-doc-soc",
  emits: ["resetFilters", "getDocumentiEnte"],
  components: {
    SelectInput,
    Datepicker,
  },
  props: {},
  setup(props, { emit }) {
    const store = useStore();

    const dataDaDocEnte = computed(() => store.getters.dataDaDocEnte);
    const dataADocEnte = computed(() => store.getters.dataADocEnte);
    const idTipoDocEnte = computed(() => store.getters.idTipoDocEnte);
    const permessiDocEnte = computed(() => store.getters.permessiDocEnte);

    const setDataDaDocEnte = (event) => {
      store.commit("setDataDaDocEnte", event);
    };
    const setDataADocEnte = (event) => {
      store.commit("setDataADocEnte", event);
    };
    const setIdTipoDocEnte = (event) => {
      store.commit("setIdTipoDocEnte", event);
    };
    const setPermessiDocEnte = (event) => {
      store.commit("setPermessiDocEnte", event);
    };

    const stagione = computed(() => store.getters.stagioneSelected);
    watch(stagione, () => {
      emit("getDocumentiEnte");
    });

    const instance = getCurrentInstance();
    const globalApi =
      instance.appContext.config.globalProperties.$apiListGlobal;
    const societa_tipo_documenti = computed(() =>
      store.getters.getStateFromName("societa_tipo_documenti")
    );
    const keys = ref("std");
    if (!societa_tipo_documenti.value) {
      store.dispatch("setStoreData", {
        keys: { keys: keys.value },
        apiLink: globalApi.COMBOLIST_GET,
      });
    }

    return {
      dataDaDocEnte,
      dataADocEnte,
      idTipoDocEnte,
      permessiDocEnte,
      setDataDaDocEnte,
      setDataADocEnte,
      setIdTipoDocEnte,
      setPermessiDocEnte,
      societa_tipo_documenti,
      format,
      loaded: computed(() =>
        store.getters.getStateFromName("loadeddocumenti_ente_list")
      ),
    };
  },
});
</script>

<style></style>
