<template>
  <div class="modal fade" tabindex="-10" id="kt_modal_add-doc-ente">
    <div class="modal-dialog">
      <loading
        :active="isLoading"
        :is-full-page="fullPage"
        :z-index="1100"
      ></loading>
      <Form
        @submit="onSubmit"
        :validation-schema="schema"
        @invalid-submit="onInvalidSubmit"
      >
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Aggiungi documento</h5>

            <!--begin::Close-->
            <div
              class="btn btn-icon btn-sm btn-active-secondary"
              style="border: 0; !important"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="bi bi-x-lg"> </i>
            </div>
            <!--end::Close-->
          </div>

          <div class="modal-body text-start">
            <div class="card-body p-6">
              <div class="ps-5 pe-5">
                <div class="row pb-4">
                  <div class="col-md-6">
                    <label class="fw-bold text-gray-600">
                      Data documento*</label
                    >
                    <button class="bugSolver"></button>
                    <Datepicker
                      v-model="dataDoc"
                      placeholder=""
                      :format="format"
                      :monthChangeOnScroll="false"
                      :enableTimePicker="false"
                      inputClassName="h-28"
                      autoApply
                      @update:model-value="dataDoc = fromUTCtoISODate($event)"
                    />
                    <Field
                      name="data_riportata"
                      type="hidden"
                      class="form-control"
                      v-model="dataDoc"
                    />
                    <ErrorMessage name="data_riportata" style="color: red" />
                  </div>
                  <div class="col-md-6">
                    <label class="fw-bold text-gray-600">Tipologia*</label>

                    <SelectInputEvento
                      :options="societa_tipo_documenti"
                      parameter="label"
                      :value="id_tipo"
                      name="id_tipo"
                      @changeSelect="
                        id_tipo = $event.id;
                        oggetto =
                          $event.id == 24
                            ? 'Modulo Codice Condotta'
                            : $event.id == 25
                            ? 'Modulo Modello Organizzazione'
                            : '';
                        descrizione =
                          $event.id == 24
                            ? 'Modulo Codice Condotta'
                            : $event.id == 25
                            ? 'Modulo Modello Organizzazione'
                            : '';
                      "
                    />
                    <ErrorMessage name="id_tipo" style="color: red" />
                  </div>
                </div>
                <div class="row pb-4">
                  <div class="col-md-12">
                    <label class="fw-bold text-gray-600">Oggetto*</label>

                    <Field
                      name="ogg"
                      type="text"
                      class="form-control"
                      v-model="oggetto"
                    />
                    <ErrorMessage name="ogg" style="color: red" />
                  </div>
                </div>
                <div class="row pb-4">
                  <div class="col-md-12">
                    <label class="fw-bold text-gray-600">Descrizione*</label>

                    <!-- <TextInput
                      name="descr"
                      type="text"
                      :textArea="true"
                      :inputValue="descrizione"
                    /> -->
                    <Field
                      name="descr"
                      type="text"
                      class="form-control"
                      as="textarea"
                      v-model="descrizione"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-1">
                    <label class="fw-bold text-gray-600">File*</label>
                  </div>

                  <div class="col-lg-11 d-flex ps-0 pe-xl-11">
                    <div
                      class="image-input image-input-outline ms-2"
                      data-kt-image-input="true"
                      style="background-image: url(media/avatars/blank.png)"
                    ></div>
                  </div>
                  <DropZone
                    @files-dropped="addFile($event, supportedFormatSocieta)"
                    #default="{ dropZoneActive }"
                  >
                    <label for="file-input-add-soc" style="cursor: pointer">
                      <span v-if="dropZoneActive">
                        <span>Lascia qui il file </span>
                        <span class="smaller">per aggiungerlo</span>
                      </span>
                      <span v-else>
                        <span>Trascina qui il tuo file</span>
                        <span class="smaller">
                          oppure
                          <strong><em>clicca qui</em></strong>
                          per selezionare il file
                        </span>
                      </span>
                    </label>
                    <input
                      type="file"
                      id="file-input-add-soc"
                      style="display: none"
                      :accept="supportedFormatSocieta"
                      @change="onInputChange"
                    />
                    <ul class="image-list" v-if="file.id">
                      <FilePreview
                        :key="file.id"
                        :file="file"
                        tag="li"
                        @remove="removeFile"
                      />
                    </ul>
                  </DropZone>
                  <div class="form-text">
                    Tipi di file concessi: {{ supportedFormatSocieta }}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="modal-footer">
            <span
              type="button"
              class="me-3 badge bg-light-danger rounded text-danger fs-6"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              Chiudi
            </span>
            <button
              type="submit"
              class="badge bg-secondary rounded blueFit fs-6"
              :disabled="disableButton"
            >
              Salva Modifiche
            </button>
          </div>
        </div>
      </Form>
    </div>
  </div>
</template>

<script>
import { ref, getCurrentInstance, computed } from "vue";

import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";

import TextInput from "../../utility/inputs/TextInput.vue";
import SelectInputEvento from "../../utility/inputs/SelectInputEvento.vue";

import Datepicker from "vue3-date-time-picker";
import "vue3-date-time-picker/dist/main.css";
import { format, fromUTCtoISODate } from "@/composables/formatDate.js";

import { useStore } from "vuex";
import { sendFiles } from "@/requests/fileSender";
import { alertFailed, alertSuccess } from "@/composables/swAlert";

//componente documenti
import DropZone from "../../Drag-and-Drop-File/DropZone.vue";
import FilePreview from "../../Drag-and-Drop-File/FilePreview.vue";
import useFileList from "@/composables/file-list";

import Loading from "vue3-loading-overlay";
import "vue3-loading-overlay/dist/vue3-loading-overlay.css";

export default {
  name: "documenti-ente-add",
  emits: ["documentAdded"],
  props: ["ente"],
  components: {
    Loading,
    // TextInput,
    SelectInputEvento,
    Form,
    Datepicker,
    Field,
    ErrorMessage,
    DropZone,
    FilePreview,
  },
  setup(props, { emit }) {
    const store = useStore();
    const instance = getCurrentInstance();
    const globalApi =
      instance.appContext.config.globalProperties.$apiListGlobal;
    const societa_tipo_documenti = computed(() =>
      store.getters.getStateFromName("enti_aggregati_tipo_documenti")
    );
    const keys = ref("eatd");
    // const persona = ref("false");
    // const societa = ref("true");
    // verifico se le lookup esistono, altrimanti le carico da api
    if (!societa_tipo_documenti.value) {
      store.dispatch("setStoreData", {
        keys: { keys: keys.value },
        apiLink: globalApi.COMBOLIST_GET,
      });
    }

    const schema = yup.object().shape({
      id_tipo: yup.string().required("Obbligatorio"),
      ogg: yup.string().required("Obbligatorio"),
      descr: yup.string().when(["id_tipo"], {
        is: (id_tipo) => {
          return id_tipo != 17 && id_tipo != 18;
        },
        then: yup.string().required("Obbligatorio"),
        otherwise: yup.string(),
      }),
      data_riportata: yup.string().required("Obbligatorio").nullable(),
    });

    // risoluzione bug datapicker e acl presettato a id 0!
    const dataDoc = ref(null);

    // gestione file nuova
    const { file, addFile, removeFile } = useFileList();
    function onInputChange(e) {
      addFile(e.target.files, supportedFormatSocieta.value);
      e.target.value = null; // reset so that selecting the same file again will still cause it to fire this change
    }

    let bodyFormData = new FormData();
    const selectedStagione = computed(() => store.getters.stagioneSelected);

    const disableButton = ref(false);
    const isLoading = ref(false);

    const onSubmit = async (values, { resetForm }) => {
      disableButton.value = true;
      isLoading.value = true;
      Object.entries(values).map((item) => {
        //
        bodyFormData.append(item[0], item[1]);
      });
      bodyFormData.append("id_societa", props.ente.id);
      bodyFormData.append("anno", selectedStagione.value);
      bodyFormData.append("acl", 0);
      if (file.value.file) {
        bodyFormData.append("file", file.value.file);
        file.value.status = "loading";
      } else {
        alertFailed("Inserire un file!");
        bodyFormData = new FormData();
        disableButton.value = false;
        isLoading.value = false;
        return;
      }

      await sendFiles(
        bodyFormData,
        globalApi.AGGIUNGI_DOCUMENTO_AFFILIAZIONE_ENTE
      ).then((res) => {
        const { status, data } = res;
        if (status != 200) {
          file.value.status = false;
          alertFailed(
            data.message
              ? data.message
              : "Attenzione! Si è verificato un errore. Riprovare più tardi"
          );
        } else {
          file.value.status = true;
          alertSuccess(
            "Documento aggiunto con successo",
            "kt_modal_add-doc-ente"
          );
          emit("documentAdded");
          //reset form
          bodyFormData = new FormData();
          file.value = {};
          dataDoc.value = null;
          resetForm();
        }
        disableButton.value = false;
        isLoading.value = false;
      });
    };

    const onInvalidSubmit = () => {
      alertFailed("Compilare i campi obbligatori");
    };

    const supportedFormatSocieta = ref(
      ".pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .jpeg, .jpg"
    );

    const id_tipo = ref(0);
    const oggetto = ref("");
    const descrizione = ref("");

    return {
      descrizione,
      id_tipo,
      oggetto,
      societa_tipo_documenti,
      onSubmit,
      onInvalidSubmit,
      schema,
      format,
      fromUTCtoISODate,

      file,
      dataDoc,

      addFile,
      removeFile,
      onInputChange,
      disableButton,
      isLoading,
      supportedFormatSocieta,
    };
  },
};
</script>

<style scoped>
.modal-content {
  width: 140%;
}

.modal-body {
  padding: 0px;
}

@media (max-width: 991.98px) {
  .modal-dialog {
    max-width: 52%;
    margin: 9rem;
    margin-top: 5rem;
  }
}

@media (max-width: 575.98px) {
  .modal-dialog {
    max-width: 70%;
    margin: 3px;
    margin-top: 4rem;
  }
}

.image-input [data-kt-image-input-action] {
  cursor: pointer;
  position: absolute;
  transform: none;
}

.btn.btn-icon:not(.btn-outline):not(.btn-dashed):not(.border-hover):not(.border-active):not(.btn-flush) {
  border: solid 1px;
  border-color: #d6d8dd;
}

.btn.btn-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding-right: 3px;
  height: 28px;
  width: 28px;
}

.image-input [data-kt-image-input-action="change"] {
  left: 0%;
  top: 0%;
}
</style>
